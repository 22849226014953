<template>
	<div class="recipes">
		<Header />
		<div class="content">
			<div
				v-infinite-scroll="loadMore"
				:infinite-scroll-disabled="loadingData"
				:infinite-scroll-distance="0"
				infinite-scroll-throttle-delay="1600"
			>
				<MainColumn>
					<p class="top-title color-aili-white font-size-50">
						Ricette
					</p>
					<v-fade-transition>
						<CategoryHeaderRecipes v-if="recipeCategories.length > 0" @onChange="onCategoryChange" />
					</v-fade-transition>
					<v-fade-transition>
						<CategoryContentRecipes
							v-if="posts || tags || authors"
							:authors="authors"
							:masterChefs="masterChefs"
							:end="end"
							:per_page="per_page"
							:posts="posts"
							:selectedCategoryTitle="selectedCategoryTitle"
							:tags="tags"
							:term="term"
						/>
					</v-fade-transition>
				</MainColumn>
			</div>
		</div>
	</div>
</template>

<script>
import Header from '@/components/ui/Header.vue';
import CategoryHeaderRecipes from '@/components/ui/CategoryHeaderRecipes.vue';
import CategoryContentRecipes from '@/components/ui/CategoryContentRecipes.vue';

export default {
	components: {
		Header,
		CategoryHeaderRecipes,
		CategoryContentRecipes
	},
	metaInfo() {
		let postFakeData = [];
		postFakeData['yoast_meta'] = [
			{
				content: 'Associazione AILI',
				property: 'og:site_name'
			},
			{
				content: 'it_IT',
				property: 'og:locale'
			},
			{
				content: 'Home - Archivio Ricette',
				property: 'og:title'
			}
		];
		return this.formatYoastMeta(postFakeData);
	},
	data() {
		return {
			authors: false,
			masterChefs: false,
			chef_loaded: false,
			end: false,
			max_page: 999,
			page: 1,
			per_page: 3,
			posts: false,
			selectedCategoryTitle: false,
			tags: false,
			term: false,
			term_id: false
		};
	},
	async mounted() {
		this.updateProperty({
			property: 'pageColor',
			value: 'aili-pink'
		});
		await this.getRecipeCategories();

		let categoryParams = this.$route.params.category ? this.$route.params.category : '';
		let selectedCategory;

		this.recipeCategories.map((category) => {
			if (category.slug == categoryParams) {
				selectedCategory = category;
			}
		});
		await this.getContent(selectedCategory);
	},
	methods: {
		async getContent(category) {
			let response_authors = await this.$api.get('/qzr-data/authors/chefs');
			let authors = response_authors.data;
			this.authors = authors.filter(
				(author) => author.master === null || !author.master[0] || author.master[0].length === 0
			);
			this.masterChefs = authors.filter(
				(author) => author.master !== null && author.master[0] && author.master[0].length > 0
			);

			this.page = 1;
			this.term = category ? category.slug : '';
			this.selectedCategoryTitle = category ? category.name : '';
			switch (this.term) {
				case 'tags': {
					// All tags
					let response_tags = await this.$api.get('/wp/v2/tags', {
						params: {
							per_page: 100
						}
					});
					this.tags = response_tags.data;
					break;
				}

				default: {
					let response = await this.getRecipes({
						hide_empty: true,
						'recipe-category-slug': this.term,
						per_page: this.per_page,
						page: this.page
					});
					this.posts = response.data;
					break;
				}
			}

			let yoast_meta = response_authors.data[0].yoast_meta;
			yoast_meta[1].content = 'archive';
			let term = this.term ? this.convertSlug(this.term) : 'ricette';
			yoast_meta[2].content = 'Archivio ' + term + ' - ' + yoast_meta[4].content;

			this.updateProperty({
				property: 'seo',
				value: yoast_meta
			});
		},
		async getRecipes(params) {
			if (params['recipe-category-slug'] && params['recipe-category-slug'] === '') {
				delete params['recipe-category-slug'];
			}
			let response = await this.$api.get('/wp/v2/recipe', {
				params: params
			});
			this.max_page = response.headers['x-wp-totalpages'];
			return response;
		},
		async onCategoryChange(category) {
			await this.getContent(category);
		},
		async loadMore() {
			if (!this.posts) return;

			if (parseInt(this.page) < parseInt(this.max_page) && this.term !== '') {
				this.page = this.page + 1;
				let response = await this.getRecipes({
					hide_empty: true,
					'recipe-category-slug': this.term,
					per_page: this.per_page,
					page: this.page
				});
				let result = this.posts.concat(response.data);
				this.max_page = response.headers['x-wp-totalpages'];

				this.posts = result;
			}
		},
		clean(obj) {
			for (var propName in obj) {
				if (obj[propName] === null || obj[propName] === undefined || obj[propName] === false) {
					delete obj[propName];
				}
			}
			return obj;
		}
	}
};
</script>

<style lang="scss" scoped>
.recipes {
  min-height: 100vh;
  padding-bottom: $footer-distance;
}

h1 {
  padding: 0;
  padding-bottom: $spacing-1;
}

h2,
h3 {
  margin-bottom: $spacing-1;
}

.panel {
  position: relative;
  padding: $spacing-1;
}
</style>
