<template>
	<svg
		class="arrow"
		:class="{
			[renderedColor]: true,
			[type ? type : 'left']: true,
			disabled: disabled,
			[renderedColor + '-active']: hover,
		}"
		xmlns="http://www.w3.org/2000/svg"
		width="40"
		height="40"
		viewBox="0 0 40 40"
	>
		<g id="Raggruppa_133" data-name="Raggruppa 133" transform="translate(-7410 -3647)">
			<g id="Raggruppa_132" data-name="Raggruppa 132">
				<g id="Raggruppa_131" data-name="Raggruppa 131" transform="translate(5965 -106)">
					<g
						id="Ellisse_32"
						data-name="Ellisse 32"
						transform="translate(1445 3753)"
						fill="transparent"
						:stroke="renderedColor"
						stroke-width="2"
					>
						<circle cx="20" cy="20" r="20" stroke="none" />
						<circle cx="20" cy="20" r="19" fill="none" />
					</g>
					<line
						id="Linea_11"
						data-name="Linea 11"
						x2="20"
						transform="translate(1454.5 3773.5)"
						fill="none"
						:stroke="renderedColor"
						stroke-width="2"
					/>
					<g
						id="Poligono_1"
						data-name="Poligono 1"
						transform="translate(1476.5 3768) rotate(90)"
						:fill="renderedColor"
					>
						<path
							d="M 10.15968990325928 6.5 L 1.040309906005859 6.5 L 5.599999904632568 0.8003875017166138 L 10.15968990325928 6.5 Z"
							stroke="none"
						/>
						<path
							d="M 5.599999904632568 1.60078763961792 L 2.080630302429199 6 L 9.119369506835938 6 L 5.599999904632568 1.60078763961792 M 5.599999904632568 0 L 11.19999980926514 7 L 0 7 L 5.599999904632568 0 Z"
							stroke="none"
							:fill="renderedColor"
						/>
					</g>
				</g>
			</g>
		</g>
	</svg>
</template>

<script>
export default {
	props: ['color', 'type', 'disabled', 'hover'],
	computed: {
		renderedColor() {
			switch (this.color) {
				case 'aili-yellow':
					return '#ff8602';

				case 'aili-blue':
					return '#00aeef';

				case 'aili-green':
					return '#00c89d';

				case 'aili-purple':
					return '#7209b7';

				case 'aili-pink':
					return '#f62584';

				default:
					return this.color;
			}
		},
	},
};
</script>

<style lang="scss" scoped>
.left {
	transform: rotate(180deg);
}
.disabled {
	opacity: 0.3;
	pointer-events: none;
}
.arrow {
	cursor: pointer;
}

* {
	transition: 0.5s fill;
}

/* WHITE */
.\#fff-active circle,
.\#fff:hover circle {
	fill: #fff;
}

.\#fff-active path,
.\#fff-active line,
.\#fff:hover path,
.\#fff:hover line {
	fill: #00aeef;
	stroke: #00aeef;
}

/* GREEN LIGHT */
.\#00c89d-active circle,
.\#00c89d:hover circle {
	fill: #00cca0;
}

.\#00c89d-active line,
.\#00c89d-active path,
.\#00c89d:hover path,
.\#00c89d:hover line {
	fill: #fff;
	stroke: #fff;
}
/* GREEN */
.\#00cca0-active circle,
.\#00cca0:hover circle {
	fill: #00cca0;
}

.\#00cca0-active path,
.\#00cca0-active line,
.\#00cca0:hover path,
.\#00cca0:hover line {
	fill: #fff;
	stroke: #fff;
}
/* BLUE */
.\#00aeef-active circle,
.\#00aeef:hover circle {
	fill: #00aeef;
}

.\#00aeef-active path,
.\#00aeef-active line,
.\#00aeef:hover path,
.\#00aeef:hover line {
	fill: #fff;
	stroke: #fff;
}
/* YELLOW */
.\#ff8602-active circle,
.\#ff8602:hover circle {
	fill: #ff8602;
}

.\#ff8602-active path,
.\#ff8602-active line,
.\#ff8602:hover path,
.\#ff8602:hover line {
	fill: #fff;
	stroke: #fff;
}
/* PINK */
.\#f72585-active circle,
.\#f72585:hover circle {
	fill: #f72585;
}

.\#f72585-active path,
.\#f72585-active line,
.\#f72585:hover path,
.\#f72585:hover line {
	fill: #fff;
	stroke: #fff;
}
/* PURPLE */
.\#7209b7-active circle,
.\#7209b7:hover circle {
	fill: #7209b7;
}

.\#7209b7-active path,
.\#7209b7-active line,
.\#7209b7:hover path,
.\#7209b7:hover line {
	fill: #fff;
	stroke: #fff;
}
</style>
