import { render, staticRenderFns } from "./Wave.vue?vue&type=template&id=0988fae6&scoped=true&"
import script from "./Wave.vue?vue&type=script&lang=js&"
export * from "./Wave.vue?vue&type=script&lang=js&"
import style0 from "./Wave.vue?vue&type=style&index=0&id=0988fae6&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0988fae6",
  null
  
)

export default component.exports