<template>
	<div ref="container" class="wave-container" :class="[position]">
		<svg class="wave" width="100%" height="100%" version="1.1" xmlns="http://www.w3.org/2000/svg" id="intro">
			<path transform="translate(0,10)" ref="wave" d="" :fill="color" />
		</svg>
	</div>
</template>

<script>
export default {
	props: ['position', 'color', 'reverse'],
	data() {
		return {
			last_update: null,
			speed: 0.3,
			total_time: 0,
			wave: null,
			wave_delta: 5,
			wave_height: 15,
			wave_width: 0,
			wave_points: 5,
		};
	},
	mounted() {
		if (this.$vuetify.breakpoint.width <= 1024) {
			this.wave_points = 3;
		}
		this.wave = this.$refs.wave;
		this.wave_width = this.$refs.container.clientWidth;
		setInterval(this.tick, 15);
	},
	methods: {
		buildPath(points) {
			if (this.$refs.container) {
				this.wave_width = this.$refs.container.clientWidth;
			}
			let SVG_string = 'M ' + points[0].x + ' ' + points[0].y;

			let cp0 = {
				x: (points[1].x - points[0].x) / 2,
				y: 2 * (points[1].y - points[0].y),
			};

			SVG_string +=
				' C ' + cp0.x + ' ' + cp0.y + ' ' + cp0.x + ' ' + cp0.y + ' ' + points[1].x + ' ' + points[1].y;

			let prev_cp = cp0;
			let inverted = -1;

			for (let i = 1; i < points.length - 1; i++) {
				let cp1 = {
					x: points[i].x - prev_cp.x + points[i].x,
					y: points[i].y - prev_cp.y + points[i].y,
				};

				SVG_string +=
					' C ' +
					cp1.x +
					' ' +
					cp1.y +
					' ' +
					cp1.x +
					' ' +
					cp1.y +
					' ' +
					points[i + 1].x +
					' ' +
					points[i + 1].y;
				prev_cp = cp1;
				inverted = -inverted;
			}

			SVG_string += ' L ' + this.wave_width + ' -10';
			SVG_string += ' L 0 -10 Z';
			return SVG_string;
		},
		calculateWavePoints(factor) {
			let points = [];
			for (let i = 0; i <= this.wave_points; i++) {
				let x = (i / this.wave_points) * this.wave_width;
				let sin_seed = (factor + (i + (i % this.wave_points))) * this.speed * 100;
				let sin_height = Math.sin(sin_seed / 100) * this.wave_delta;
				let yPos = Math.sin(sin_seed / 100) * sin_height + this.wave_height;
				points.push({ x: x, y: yPos });
			}

			return points;
		},
		tick() {
			let now = new Date();
			if (this.last_update) {
				let elapsed = (now - this.last_update) / 1000;
				this.last_update = now;

				this.total_time += elapsed;

				let factor = this.total_time * Math.PI;
				this.wave.setAttribute('d', this.buildPath(this.calculateWavePoints(factor)));
			} else {
				this.last_update = now;
			}
		},
	},
};
</script>

<style lang="scss" scoped>
.wave-container {
  position: absolute;
  right: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  pointer-events: none;
  .wave {
    @media (max-width: $tablet-l) {
      width: 100%;
      max-width: 100%;
    }
  }
}
.bottom {
  bottom: 5px;
  transform: rotate(180deg);
  transform: translateY(100%);
}
.top {
  top: -5px;
}
</style>
