<template>
	<v-fade-transition>
		<div class="content-shadow" v-if="!loadingData">
			<div v-if="searchTerm">
				<div :class="'panel background-aili-pink-light'">
					<h2 :class="'color-aili-pink font-size-35'">Risultati per: {{ searchTerm }}</h2>
					<NoResultFound v-if="searchResults.length == 0" />
					<div v-for="(result, j) in searchResults" :key="j">
						<CardResultRecipe
							:slug="result.slug"
							:preview_data="result.preview_data"
							:author="result.author_data"
							:image="result.featured_media_data"
							:title="result.title"
							:content="result.post_excerpt"
							:categories="result['recipe-category_data']"
							style="margin-bottom: 100px"
						/>
					</div>
				</div>
			</div>

			<div v-else-if="term === '' || typeof term === 'undefined'">
				<div
					v-if="masterChefs && masterChefs.length > 0"
					:class="'master-chef-panel background-aili-pink-light'"
				>
					<h2 :class="'color-aili-pink font-size-35'">I nostri Chef</h2>
					<swiper
						v-if="swiperVisible"
						class="category-content-swiper"
						ref="swiperRecipeContent"
						:options="swiperOption"
					>
						<swiper-slide v-for="(masterChef, i) in masterChefs" :key="i">
							<a
								@click="preventDefault"
								class="author"
								:href="getUrl('ricette/chef/' + masterChef.slug)"
								:title="'Chef: ' + masterChef.first_name + ' ' + masterChef.last_name"
							>
								<div
									@click="goTo('Chef', { slug: masterChef.slug })"
									class="author-image"
									:style="'background-image:url(' + masterChef.qzr_image.thumbnail + ')'"
								></div>
								<p :class="'color-aili-pink font-size-16 mb-0'">
									{{ masterChef.first_name + ' ' + masterChef.last_name }}
								</p>
							</a>
						</swiper-slide>
					</swiper>
				</div>
				<div :class="'panel background-aili-pink-light'">
					<h2 :class="'color-aili-pink font-size-35'">Le nostre blogger</h2>
					<div class="buttons">
						<div>
							<Arrow @click.native="previousPage" type="left" color="#f72585" />
						</div>
						<div>
							<Arrow @click.native="nextPage" type="right" color="#f72585" />
						</div>
					</div>
					<swiper
						v-if="swiperVisible"
						class="category-content-swiper"
						ref="swiperRecipeContent"
						:options="swiperOption"
					>
						<swiper-slide v-for="(author, i) in authors" :key="i">
							<a
								@click="preventDefault"
								class="author"
								:href="getUrl('ricette/chef/' + author.slug)"
								:title="'Chef: ' + author.first_name + ' ' + author.last_name"
							>
								<div
									@click="goTo('Chef', { slug: author.slug })"
									class="author-image"
									:style="'background-image:url(' + author.qzr_image.thumbnail + ')'"
								></div>
								<p :class="'color-aili-pink font-size-16'">
									{{ author.first_name + ' ' + author.last_name }}
								</p>
							</a>
						</swiper-slide>
						<div
							v-if="
								$vuetify.breakpoint.width < 550 ||
									($vuetify.breakpoint.width < 768 && authors.length > 2) ||
									($vuetify.breakpoint.width < 1024 && authors.length > 3) ||
									authors.length > 5
							"
							class="swiper-pagination"
							slot="pagination"
						></div>
					</swiper>
				</div>

				<div class="panel background-aili-white">
					<Wave position="top" :color="getHexColor('aili-pink-light')" />
					<h2 :class="'color-aili-pink font-size-35'">
						In primo piano
					</h2>

					<LastRecipes :parentBlock="{ attrs: { background: '#fff' } }" />
				</div>
				<div :class="'panel background-aili-pink-light'">
					<h2 :class="'color-aili-pink font-size-25'">
						Playlists
					</h2>
					<AllTags />
				</div>

				<div v-for="(category, i) in recipeCategories" :key="i">
					<div
						:class="{
							panel: true,
							['background-aili-pink-light']: i % 2 == 0,
							'background-aili-white': i % 2 > 0
						}"
						v-if="category.name !== 'Playlist' && category.slug && category.slug !== 'chef'"
					>
						<h3 :class="'color-aili-pink font-size-25'">
							{{ category.name }}
						</h3>
						<LastRecipesByCategory
							:color="i % 2 > 0 ? 'aili-pink-light' : 'aili-white'"
							:category="category.slug"
						/>
					</div>
				</div>
			</div>

			<div v-if="term === 'chef'">
				<div class="panel background-aili-white">
					<h4 class="color-aili-pink font-size-35">I nostri Chef</h4>
				</div>
				<div
					:class="{
						panel: true,
						['background-aili-white']: i % 2 > 0,
						'background-aili-pink-light': i % 2 == 0
					}"
					v-for="(masterChef, i) in masterChefs"
					:key="masterChef.id"
				>
					<ChefResult :author="masterChef" color="aili-pink" />
				</div>

				<div
					:class="{
						panel: true,
						['background-aili-white']: (masterChefs.length + i) % 2 > 0,
						'background-aili-pink-light': (masterChefs.length + i) % 2 == 0
					}"
					v-for="(author, i) in authors"
					:key="author.id"
				>
					<ChefResult :author="author" color="aili-pink" />
				</div>
			</div>

			<div v-else-if="term === 'tags'">
				<div class="panel  background-aili-white">
					<h1
						v-if="$vuetify.breakpoint.width > 1024"
						class="color-aili-pink"
						v-html="selectedCategoryTitle"
					/>
					<div class="tags">
						<Playlist v-for="(tag, i) in tags" :key="i" :tag="tag" />
					</div>
				</div>
			</div>

			<div v-else-if="!searchTerm && posts.length > 0">
				<div class="panel background-aili-white">
					<h1
						v-if="$vuetify.breakpoint.width > 1024"
						class="color-aili-pink"
						v-html="selectedCategoryTitle"
					/>
					<div v-for="(post, i) in posts" :key="i">
						<CardResultRecipe
							:slug="post.slug"
							:preview_data="post.preview_data"
							:author="post.author_data"
							:image="post.featured_media_data"
							:title="post.title.rendered"
							:content="post.post_excerpt"
							:categories="post['recipe-category_data']"
						/>
					</div>
				</div>
			</div>
		</div>
	</v-fade-transition>
</template>
<script>
	import NoResultFound from '@/components/ui/NoResultFound.vue';
	import Arrow from '@/components/ui/Arrow.vue';
	import LastRecipesByCategory from '@/components/blocks/lazyblocks/lazyblock_last_recipes_by_category.vue';
	import LastRecipes from '@/components/blocks/lazyblocks/lazyblock_last_recipes.vue';
	import AllTags from '@/components/blocks/lazyblocks/lazyblock_all_tags.vue';
	import CardResultRecipe from '@/components/ui/CardResultRecipe.vue';
	import ChefResult from '@/components/ui/ChefResult.vue';
	import Wave from '@/components/ui/Wave.vue';
	import Playlist from './Playlist.vue';

	export default {
		components: {
			Arrow,
			NoResultFound,
			Wave,
			AllTags,
			LastRecipes,
			LastRecipesByCategory,
			CardResultRecipe,
			ChefResult,
			Playlist
		},
		props: ['posts', 'end', 'authors', 'masterChefs', 'tags', 'term', 'selectedCategoryTitle'],

		data() {
			return {
				swiperVisible: false,
				termTitle: false,
				searchResults: [],
				swiperOption: {
					slidesPerView: 1,
					spaceBetween: 25,
					pagination: {
						el: '.swiper-pagination',
						clickable: true
					},
					breakpoints: {
						550: {
							slidesPerView: 2
						},
						768: {
							slidesPerView: 3
						},
						1024: {
							slidesPerView: 5
						}
					}
				}
			};
		},
		computed: {
			swiper() {
				return this.$refs.swiperRecipeContent.$swiper;
			}
		},
		watch: {
			searchTerm: async function(newValue) {
				let params = {
					subtype: 'recipe',
					search: newValue,
					per_page: 100
				};

				if (newValue) {
					let response_search = await this.$api.get('wp/v2/search', {
						params: params
					});

					this.searchResults = response_search.data;
				} else {
					this.searchResults = [];
				}
			},
			masterChefs(newValue) {
				console.log(newValue)
			}
		},
		mounted() {
			this.updateProperty({
				property: 'loadingData',
				value: false
			});
			this.recipeCategories.map((category) => {
				if (category.slug == this.term) {
					this.termTitle = category.name;
				}
			});
			//Without this pagination is not working
			setTimeout(() => (this.swiperVisible = true));
		},
		methods: {
			changeUrl(url) {
				window.location.href = url;
			},
			nextPage() {
				this.swiper.slideNext();
			},
			previousPage() {
				this.swiper.slidePrev();
			}
		}
	};
</script>

<style lang="scss" scoped>
.buttons {
  position: absolute;
  @media (max-width: $tablet-m) {
    display: none;
  }
}

.category-content-swiper {
  width: calc(100% - 100px);
}

.recipes {
  min-height: 100vh;
}

h1 {
  margin-bottom: 50px !important;
}

h2,
h3 {
  margin-bottom: $spacing-1;
}

h4 {
  margin: 0;
}

a {
  text-decoration: none;
}

.master-chef-panel {
  position: relative;
  padding: $spacing-1 $spacing-1 0 $spacing-1;
  @media (max-width: $tablet-l) {
    padding: $spacing-0 !important;
  }
}

.panel {
  position: relative;
  padding: $spacing-1;
  @media (max-width: $tablet-l) {
    padding: $spacing-0 !important;
  }
}

.author {
  cursor: pointer;
  .author-image {
    display: inline-block;
    width: 100%;
    padding-top: 100%;
    background-size: cover;
    border-radius: 100%;
    filter: saturate(0);
  }
  p {
    text-align: center;
  }
}

.tags {
  display: flex;
  flex-wrap: wrap;
  .tag {
    position: relative;
    flex-basis: 20%;
    flex-grow: 0;
    height: 400px;
    margin: 0 $spacing-0 $spacing-0 $spacing-0;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    cursor: pointer;
    @media (max-width: $tablet-m) {
      flex-basis: 100%;
      flex-grow: 1;
    }
  }

  h3 {
    position: absolute;
    top: 50%;
    left: 50%;
    color: #fff;
    transform: translate(-50%, -50%);
  }
  .gradient:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: linear-gradient(0deg, #000 0%, rgba(0, 212, 255, 0) 100%);
  }
}
.swiper-pagination {
  position: relative;
}
</style>

<style lang="scss">
	.category-content-swiper {
		//Class inside swiper need to be declared outside of scoped
		.swiper-pagination-bullet-active {
			background-color: $aili-pink;
		}
	}
</style>
